import axios from 'axios'

  export const getCodeChallengeandCodeVerifier = async () => {
    try{
     const {data } = await axios.get('/api/users/token/codechallengeandcodeverifier')
     if(data.code_verifier && data.code_challenge){
      return data;
     }
     else
      throw new Error('Code Verifier and challenge not generated')
    }
    catch (e){
      return console.warn(e)
    }
  }