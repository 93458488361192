import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { useIdleTimer } from 'react-idle-timer'
import { AlertModal } from '@bp-digital/component-alert-modal'
import useContent from 'hooks/useContent'
import { EXPIRY_ID, IDP_TOKEN, IDP_REFRESH } from 'constants/localStorage'
import { ROUTE_LOGOUT } from 'constants/routes'
import { useHistory } from 'react-router'
import Cookies from 'js-cookie'
import getFallbackLanguage, { getLanguageFromUrl } from 'helpers/getFallbackLanguage'
import getBrandFromDomain from 'helpers/getBrandFromDomain'



const SessionExpiry = () => {
  const content = useContent('session')
  const [isRefreshingToken, setIsRefreshingToken] = useState(false)
  const [hasSessionExpired, setHasSessionExpired] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [logoutEnabled, setLogoutEnabled] = useState(false)
  const history = useHistory()
  const localStorage = window.localStorage
  const locale = Cookies.get('bpf-locale') || getLanguageFromUrl() || getFallbackLanguage()

  const brand = Cookies.get('brandfromdomain') === undefined ?  getBrandFromDomain() : Cookies.get('brandfromdomain')
  let logoutTimer = useRef()
  let response = ''
  const refreshToken = async () => {
    setIsRefreshingToken(true)
    try {
      const rtoken = localStorage.getItem(IDP_REFRESH)
      let codeverifier = localStorage.getItem('code_verifier')
      if(rtoken && codeverifier){
        response =  await axios.post(
         `/api/users/token/gettokens?refresh_token=secure`,
         { 
          brand:brand,
          rtoken: rtoken,
          codeverifier: codeverifier,
          isLocal: Cookies.get('islocal') },
         {
           skipAuthRefresh: true,
           withCredentials: true
         }
       )  
      if(response)
      {
        localStorage.setItem(EXPIRY_ID, 'expiry')
        localStorage.setItem(IDP_TOKEN, response?.data.access_token)
        localStorage.setItem(IDP_REFRESH, response?.data.refresh_token)
        setIsRefreshingToken(false)
        setHasSessionExpired(false)
      }
      else{
        console.warn('Failed to refresh session.',JSON.stringify(response))
        setLogoutEnabled(true)
      }
    }
    else
      setLogoutEnabled(true)
    } catch (e) {
      console.warn('Failed to refresh session.', e)
      setHasError(true)
    }
  }

  const logout = () => {
    window.isLoggingOut = true // tactical, avoid mass shadow render
    history.push(ROUTE_LOGOUT)
  }

  const handleOnIdle = async () => {
    setHasSessionExpired(true)
  }

  useEffect(() => {
    if (hasSessionExpired) {
      logoutTimer.current = setTimeout(() => logout(), 60000)
    } else {
      clearInterval(logoutTimer)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSessionExpired])
  
  useEffect(() => { 
    if(logoutEnabled){
      window.location.assign(window.location.protocol + '//' + window.location.host + '/' + locale + ROUTE_LOGOUT)
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutEnabled])

  useIdleTimer({
    timeout: 1000 * 60 * 14,
    onIdle: async () => {
      await handleOnIdle()
    },
    debounce: 500
  })

  if (hasSessionExpired) {
    return (
      <AlertModal
        visible={hasSessionExpired}
        hasCloseButton={false}
        primaryAction={{
          text: content?.token_expired_continue,
          isLoading: isRefreshingToken,
          onClick: refreshToken,
          dataAttributes: {
            'data-testid': 'button-continue-session'
          }
        }}
        secondaryAction={{
          text: content?.token_expired_logout,
          onClick: logout,
          dataAttributes: {
            'data-testid': 'button-logout'
          }
        }}
        title={content?.token_expired_heading}
        state={hasError ? 'warning' : 'warning'} // switch to danger when supported
        text={content?.token_expired_intro}
        size='sm'
      />
    )
  }

  return null
}

export default SessionExpiry
