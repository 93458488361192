import { formatCategoriesAndProducts } from 'helpers/restrictions'
import { RESTRICTION_TYPES } from 'constants/restrictions'

const checkEmptyValue = value => (value && !!value.toString() ? value : null)

const buildRequest = ({
  profileId,
  profileName,
  formData: { categories },
  violationId,
  isAllowNotListed,
  productCategories,
  selectedCategoryIds
}) => {
  const categoriesData = formatCategoriesAndProducts(categories, productCategories)

  const request = {
    restrictionDetail: {
      saveProfile: true,
      profileName: profileName,
      profileId: Number(profileId),
      restrictionTypes: [RESTRICTION_TYPES.PRODUCTS_AND_SERVICES],
      existingProfile: true,
      productAndServices: {
        acceptProductServiceViolation: violationId === 3 ? '0' : '1',
        alertProductServiceViolation: violationId === 1 ? '0' : '1',
        isAllowNotListed: isAllowNotListed,
        categories: categoriesData
          .filter(item => selectedCategoryIds.includes(Number(item.categoryId)))
          .map(item => ({
            categoryId: Number(item.categoryId),
            applyForAllProductsInCategory: item.applyForAllProductsInCategory,
            categoryDetails: {
              categoryLimit: item.applyForAllProductsInCategory
                ? {
                    daily: {
                      dailyMaxVolume: checkEmptyValue(item.dailyMaxVolume),
                      dailyMaxValue: checkEmptyValue(item.dailyMaxValue)
                    },
                    monthly: {
                      monthlyMaxValue: null,
                      monthlyMaxVolume: null
                    },
                    transaction: {
                      txnMaxVolume: checkEmptyValue(item.txnMaxVolume),
                      txnMaxValue: checkEmptyValue(item.txnMaxValue)
                    }
                  }
                : null,
              isAllow: item.applyForAllProductsInCategory ? Number(item.isAllow) : null,
              products: item.applyForAllProductsInCategory
                ? null
                : item.products.map(product => ({
                    productServiceId: Number(product.productId),
                    productServiceName: product.name,
                    productServiceType: product.type,
                    isAllow: Number(product.isAllow),
                    ...(Number(product.isAllow) === 1 &&
                      product.setRestriction && {
                        productDetails: {
                          ...(product.dailyMaxVolume | product.dailyMaxValue && {
                            daily: {
                              dailyMaxVolume: checkEmptyValue(product.dailyMaxVolume),
                              dailyMaxValue: checkEmptyValue(product.dailyMaxValue)
                            }
                          }),
                          ...(product.txnMaxVolume | product.txnMaxValue && {
                            transaction: {
                              txnMaxVolume: checkEmptyValue(product.txnMaxVolume),
                              txnMaxValue: checkEmptyValue(product.txnMaxValue)
                            }
                          })
                        }
                      })
                  }))
            }
          }))
      }
    }
  }

  return request
}

export default buildRequest
