import { toJS } from 'mobx'
import download from 'downloadjs'
import axios from 'axios'
import mapToDownloadFormat from '../mapToDownloadFormat'
import getSelectedSummaries from '../getSelectedSummaries'
import getSelectedInvoices from '../getSelectedInvoices'
import { INVOICE_TYPES } from 'src/pages/Transactions/InvoicesPage/constants/constants'

export const getSelectedDownload = async (
  selectedRows,
  isDownloading,
  setIsDownloading,
  setDownloadMessage,
  authorityId,
  content,
  invoicesStore,
  invoiceDownloadValidation
) => {
  if (isDownloading) return // prevent double downloads

  setIsDownloading(true)
  if (invoiceDownloadValidation) {
    const getInvoiceFromStore = invoiceKey => {
      const parts = invoiceKey.split('-')
      const summaryKey = parts[0]
      const invoiceIndex = parts[2]
      const invoice = toJS(invoicesStore.detail[summaryKey][invoiceIndex]) || {}
      return mapToDownloadFormat(invoice)
    }

    const selectedInvoices = getSelectedInvoices(selectedRows)
    const selectedSummaries = getSelectedSummaries(selectedRows, selectedInvoices)
    const invoicesToDownload = selectedInvoices.map(getInvoiceFromStore)
    const summariesToDownload = Object.keys(selectedSummaries).map(summaryKey => {
      // This currentPage reference needs to change
      // as it will not currently support multi-page selection downloads as a result
      const currentResultKey = invoicesStore.getDataKey()
      const summary = toJS(
        invoicesStore.data[currentResultKey].find(summary => {
          const { summaryStatementId, invoiceType, invoiceNumber } = summary

          if (
            (invoiceType === INVOICE_TYPES.NATIONAL || invoiceType === INVOICE_TYPES.INTERNATIONAL) &&
            summaryKey === invoiceNumber
          )
            return true
          else if (invoiceType.includes(INVOICE_TYPES.SUMMARY))
            return invoiceNumber.includes(summaryStatementId.slice(1)) && summaryKey === invoiceNumber // To strip out opu code
        })
      )
      const children = selectedSummaries[summaryKey]
      let summaryInvoices = []

      if (children && children.length) {
        summaryInvoices = children.map(child => getInvoiceFromStore(child))
      }

      return {
        id: mapToDownloadFormat(summary),
        summaryInvoices
      }
    })
    const documentIds = invoicesToDownload
    summariesToDownload.forEach(sum => {
      if (sum.id.clientNumber) {
        documentIds.push(sum.id)
      }
    })
    try {
      const preInvoiceDownloadresponse = await axios({
        method: 'post',
        url: `/api/transactions/invoices/searchinvoices?accessLevel=A&accessLevelCode=${authorityId}`,
        data: {
          documentIds: documentIds
        }
      })
      if (preInvoiceDownloadresponse.status != 200)
        throw new Error(`${preInvoiceDownloadresponse.status} ${preInvoiceDownloadresponse.statusText}`)
      return { response: preInvoiceDownloadresponse.data, nextRequest: documentIds }
    } catch (error) {
      console.error(error)
      setIsDownloading(false)
      setDownloadMessage({
        id: 'download-failed',
        position: 'right-bottom',
        icon: 'Alert',
        title: content?.download_error_title || 'download_error_title',
        content: content?.download_error_content || 'download_error_content',
        autoClose: 5000
      })
    }
  } else {
    const documentIds = selectedRows
    try {
      const response = await fetch('/api/transactions/invoices/filedownload', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          documentIds
        })
      })

      if (!response.ok) throw new Error(`${response.status} ${response.statusText}`)

      const blob = await response.blob()

      download(blob, 'invoices.zip')

      setIsDownloading(false)
      setDownloadMessage({
        id: 'download-complete',
        position: 'right-bottom',
        icon: 'CheckLargeSolid',
        title: content?.download_success_title || 'download_success_title',
        content: content?.download_success_content || 'download_success_content',
        autoClose: 5000
      })
    } catch (error) {
      console.error(error)
      setIsDownloading(false)
      setDownloadMessage({
        id: 'download-failed',
        position: 'right-bottom',
        icon: 'Alert',
        title: content?.download_error_title || 'download_error_title',
        content: content?.download_error_content || 'download_error_content',
        autoClose: 5000
      })
    }
  }
}
