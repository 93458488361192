import { Button } from '@bp-digital/component-button'
import { ArchivedInvoice, SummaryStatement } from 'hooks/api/useApiGetArchivedInvoices'
import useContent from 'hooks/useContent'
import download from 'downloadjs'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { CommandBarContainer, CommandBarEnd, CommandBarStart } from './CommandBar.styled'
import { Toast } from '@bp-digital/component-toast'
import { ToastItemProps } from '@bp-digital/component-toast/dist/ToastItem'
import mapToDownloadFormat from '../helpers/mapToDownloadFormat'

interface CommandBarProps {
  selectedSummaries?: SummaryStatement[]
  selectedInvoices?: ArchivedInvoice[]
  openFilter?: () => void
  disableFilter?: boolean
}

const toastMessageForDownload: ToastItemProps = {
  id: 'download-message',
  position: 'right-bottom',
  icon: 'Info',
  title: 'Download complete',
  content: 'Your invoice(s) downloaded successfully.',
  autoClose: 5000,
  hasCloseButton: true
}

const CommandBar = ({
  selectedSummaries = [],
  selectedInvoices = [],
  openFilter: openfilter = () => undefined,
  disableFilter
}: CommandBarProps) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadMessage, setDownloadMessage] = useState<ToastItemProps>()
  const content = useContent('transactions-invoices') || {}
  const history = useHistory()
  const selectedCount = selectedInvoices.length + selectedSummaries.length

  const handleDownload = () => {
    if (isDownloading) return

    setIsDownloading(true)

    const toDownload = [...selectedSummaries, ...selectedInvoices]?.map(i => {
      return {
        id: mapToDownloadFormat(i)
      }
    })
    const documentIds: {
      documentNumber: number
      clientISOCode: string | undefined
      invoiceType: string
      clientNumber: number
      invoiceDate: string
    }[] = []
    toDownload.forEach(items => {
      if (items.id.clientNumber) {
        documentIds.push(items.id)
      }
    })

    fetch('/api/transactions/invoices/filedownload', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      body: JSON.stringify({
        documentIds: documentIds
      })
    })
      .then(response => {
        if (!response.ok) throw new Error(`${response.status} ${response.statusText}`)
        return response.blob()
      })
      .then(blob => {
        download(blob, 'invoices.zip')
        setIsDownloading(false)
        setDownloadMessage(toastMessageForDownload)
      })
      .catch(error => {
        console.error(error)
        setIsDownloading(false)
        setDownloadMessage({
          ...toastMessageForDownload,
          title: 'Download failed',
          content: 'Archive Invoice(s) failed to download. Please try again.',
          autoClose: 10000,
          icon: 'Alert'
        })
      })
  }

  return (
    <>
      <CommandBarContainer>
        <CommandBarStart>
          <Button size='lg' iconName='LeftLarge' appearance='tertiary' onClick={history.goBack}>
            {content?.masthead_back ?? 'masthead_back'}
          </Button>
        </CommandBarStart>
        <CommandBarEnd>
          <Button size='lg' onClick={openfilter} disabled={disableFilter}>
            {content?.tableFilter_ctaFilter ?? 'tableFilter_ctaFilter'}
          </Button>

          <Button
            iconName='Download'
            size='lg'
            disabled={selectedCount === 0 || selectedCount > 10}
            onClick={handleDownload}
          >
            {content?.archive_download?.replace('{{count}}', selectedCount) ?? 'archive_download'}
          </Button>
        </CommandBarEnd>
      </CommandBarContainer>

      <Toast appearance='dark' toast={downloadMessage ?? {}} />
    </>
  )
}

export default CommandBar
