import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import { useRootStore } from 'src/contexts/StoreContext'
import PageHeader from 'src/components/layout/PageHeader'
import { ROUTE_EV_PRICE_LIST } from 'src/constants/routes'
import { Button } from '@bp-digital/component-button'
import EVTable from './EVTable'
import ButtonsWrapper from 'src/components/layout/ButtonsWrapper'
import GetEVPriceList from './GetEVPriceList'
import { CommandMenu } from '@bp-digital/component-command-menu'
import './style.css'
import { NoResults } from 'components/dataDisplay/ReactTable/NoResults'
import { Text } from '@bp-digital/component-typography'

const EVPriceListPage = () => {
  const { contentStore, userStore } = useRootStore()
  const [evPriceListData, setEvPriceListData] = useState([])
  const [title, setTitle] = useState([
    {
      items: [
        { id: 'AT-EV', label: 'Austria' },
        { id: 'DE-EV', label: 'Germany' },
        { id: 'NL-EV', label: 'Netherland' },
        { id: 'UK-EV', label: 'United Kingdom' },
        { id: 'PT-EV', label: 'Portugal' },
        { id: 'LU-EV', label: 'Luxemburg' },
        { id: 'PL-EV', label: 'Poland' },
        { id: 'ES-EV', label: 'Spain' }
      ],
      disabled: false,
      size: 'lg',
      onChange: e => handleChange(e)
    }
  ])
  const authorityId = userStore.selectedAuthorityId

  const content = contentStore.getPage('transactions-evpricelist')

  useEffect(() => {
    if (!content) {
      contentStore.getContent('transactions-evpricelist')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  useEffect(() => {
    const mapping = {
      A: { id: 'AT-EV', title: 'Austria' },
      D: { id: 'DE-EV', title: 'Germany' },
      N: { id: 'NL-EV', title: 'Netherland' },
      U: { id: 'UK-EV', title: 'United Kingdom' },
      P: { id: 'PT-EV', title: 'Portugal' },
      L: { id: 'LU-EV', title: 'Luxemburg' },
      K: { id: 'PL-EV', title: 'Poland' },
      E: { id: 'ES-EV', title: 'Spain' }
    }

    const defaultTitle = { id: 'DE-EV', title: 'Germany' }
    const newTitle = mapping[authorityId[0]] || defaultTitle

    setTitle([
      {
        ...title[0],
        ...newTitle
      }
    ])
  }, [authorityId])

  const handleChange = event => {
    setTitle(prev => {
      return prev.map(item => {
        return {
          ...item,
          title: item.items.find(i => i.id === event).label,
          id: item.items.find(i => i.id === event).id
        }
      })
    })
  }

  useEffect(() => {
    if (title[0].id !== undefined) {
      handleSubmit()
    }
  }, [title])

  const handleSubmit = async () => {
    const dateVar = new Date()
    const day = dateVar.getDay()
    let diff
    if (day === 0) {
      // If today is Sunday
      diff = 6 // Get the previous Monday
    } else {
      // If today is any other day
      diff = day - 1 // Get the previous Monday
    }
    dateVar.setDate(dateVar.getDate() - diff) // Subtract the number of days from the current date
    const formattedDate = dateVar.toISOString().split('T')[0]
    const priceType = title[0].id // Define the 'priceType' variable
    const date = formattedDate // Date will be Friday of the each week
    const origin = 'Cardex' // Define the 'origin' variable
    const evPriceList = await GetEVPriceList(priceType, date, origin)
    setEvPriceListData(evPriceList?.evpricelistdata || [])
  }

  return (
    <>
      <PageHeader
        title={content?.evpricelist_title || 'EV-PriceList'}
        breadcrumbs={[
          { to: ROUTE_EV_PRICE_LIST, title: content?.evpricelist_title, ariaLabel: content?.evpricelist_title }
        ]}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        <div className='ev-page-div'>
          <ButtonsWrapper>
            {title.map(({ text, ...rest }, index) => {
              const key = `table-control-${index}`

              if (rest.items) {
                return <CommandMenu key={key} {...rest} />
              }

              return (
                <Button key={key} size='md' {...rest}>
                  {text}
                </Button>
              )
            })}
          </ButtonsWrapper>
        </div>
        {evPriceListData?.length > 0 ? (
          <>
            <EVTable data={evPriceListData} date={content?.evpricelist_date_keyword || 'Date'} />
          </>
        ) : (
          <NoResults message={content?.evpricelist_noresultsmessage || 'No prices available for this country'} />
        )}
        <div className='ev-page-note'>
          {title[0].id && (
            <Text size='lg'>
              {content?.evpricelist_note_keyword || 'Note'}:{' '}
              {
                content?.[
                  `evpricelist_${
                    Array.isArray(title) && title.length > 0 && title[0].id
                      ? title[0].id.substring(0, 2).toLowerCase() === 'uk'
                        ? 'en'
                        : title[0].id.substring(0, 2).toLowerCase()
                      : null
                  }_note`
                ]
              }
            </Text>
          )}
        </div>
      </InnerPageWrapper>
    </>
  )
}

export default observer(EVPriceListPage)
