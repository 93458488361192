import { Badge } from '@bp-digital/component-badge'
import CountryFlag from 'components/dataDisplay/DataTable/components/CountryFlag'
import BrandIcon from 'components/dataDisplay/BrandIcon'
import BaseDataStore from '../BaseDataStore'
import { getUserFormattedDate } from 'helpers/getUserFormattedDate'
import DATE_FILTERS from 'constants/dateFilters'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import axios from 'axios'
import { MAX_TRANSACTION_COUNT_DOWNLOAD } from 'src/constants/transactions'
import pako from 'pako'

dayjs.extend(customParseFormat)

const getStateFromStatus = (status = '') => {
  if (status === 'invoiced' || status === 'authorised') {
    return 'success'
  } else if (status === 'declined' || status === 'unauthorised') {
    return 'danger'
  }

  return 'warning'
}

const createDateFilter = ({ from = '', to = '', dateType = null } = {}) => [
  {
    filterProfileName: '',
    filterProfileId: '',
    filters: [
      {
        filter: {
          name: 'transactionDateTime',
          keyGroup: [
            {
              keys: ['dateType', 'from', 'to'],
              values: [
                {
                  from,
                  to,
                  dateType
                }
              ]
            }
          ]
        }
      }
    ]
  }
]

class TransactionsStore extends BaseDataStore {
  columnSorting = ['transactionDateTime', 'desc']
  defaultColumns = [
    'transactionStatus',
    'fullCardNumber',
    'siteName',
    'vehicleRegistrationNumber',
    'quantity',
    'grossValue'
  ]
  simpleFilter = ''

  constructor(root) {
    super(root, 'transactions', 'transactions-transactions')
  }

  get rows() {
    return this.results.map(transaction => {
      const status = transaction.transactionStatus?.toLowerCase()
      const statusContent = this.content?.[`transactionStatus_${status}`]

      return {
        type: 'MASTER',
        key: transaction.transactionUniqueId,
        ...transaction,
        transactionStatus: <Badge text={statusContent} state={getStateFromStatus(status)} />,
        supplyCountry: transaction.supplyCountry ? (
          <CountryFlag countryCode={transaction.supplyCountry} />
        ) : (
          transaction.supplyCountry
        ),
        transactionDateTime: transaction.transactionDateTime
          ? getUserFormattedDate(transaction.transactionDateTime, this.root.userStore.dateFormat)
          : '',
        brand: transaction.brand ? <BrandIcon brand={transaction.brand} /> : ''
      }
    })
  }

  async getAvailableColumns(authorityId) {
    const params = {
      accessLevel: 'A',
      accessLevelCode: authorityId
    }

    const response = await super.getAvailableColumns({
      method: 'get',
      url: '/api/transactions/transactioncolumns',
      availableColumnsKey: 'transactionHeader.transactionPreferenceColumn',
      extractNameFromPreferences: true,
      params
    })

    return response
  }

  getTransactionCallPayload({
    selectedHierarchy,
    dateRange = null,
    invoiceDate,
    searchTerm = this.searchTerm,
    searchColumnName = this.searchColumnName,
    transactionStatus = 'all-transactions',
    columnSorting = this.columnSorting,
    page,
    pageSize
  }) {
    let columns = this.selectedColumns
    const hasBrand = !!columns.find(item => item === 'brand')

    if (!hasBrand) {
      columns = [...columns, 'brand']
    }

    if (!columnSorting.length) {
      columnSorting = ['transactionDateTime', 'desc']
    }

    const payload = {
      activeFilters: [],
      authorityId: selectedHierarchy.accessLevel == 'A' ? selectedHierarchy.accessLevelCode : '',
      parentId: selectedHierarchy.accessLevel == 'P' ? selectedHierarchy.accessLevelCode : '',
      groupId: selectedHierarchy.accessLevel == 'G' ? selectedHierarchy.accessLevelCode : '',
      columnSorting,
      queries: [],
      transactionStatus,
      listOfColumns: columns,
      limit: pageSize,
      start: (page - 1) * pageSize + 1
    }

    if (columnSorting[0] === 'transactionStatus') {
      // implement custom search order to allow for 'translated status'
      const cstSort = ['Authorised', 'Declined', 'Uninvoiced', 'Invoiced']
        .map(status => ({ status, display: this.content?.[`transactionStatus_${status.toLowerCase()}`] || status }))
        .sort((a, b) => {
          if (a.display < b.display) return columnSorting[1] === 'desc' ? 1 : -1
          if (a.display > b.display) return columnSorting[1] === 'desc' ? -1 : 1
          return 0
        })
      payload.sortOrderer = {}
      cstSort.forEach(sortItem => {
        payload.sortOrderer[sortItem.status] = cstSort.findIndex(sortItem2 => sortItem.status === sortItem2.status) + 1
      })
    }

    const dateRegex = /(0?[1-9]|[12][0-9]|3[01])[/\-.](0?[1-9]|1[012])[/\-.]\d{4}/
    const searchTextOrDate = dateRegex.test(searchTerm)
      ? { searchDate: searchTerm.replaceAll('-', '/').replaceAll('.', '/'), searchText: '' }
      : { searchDate: '', searchText: searchTerm }

    if (searchTerm) {
      payload.searchCriteria = {
        columnName: searchColumnName || '',
        ...searchTextOrDate
      }
    }

    if (this.simpleFilter) {
      const isCustomDate = (this.simpleFilter === DATE_FILTERS.CUSTOM_DATE && dateRange) || null
      const lastYear = (this.simpleFilter === DATE_FILTERS.TWELVE_MONTHS && 'lastYear') || null
      payload.activeFilters = createDateFilter({
        from: isCustomDate && dateRange?.dateArray[0],
        to: isCustomDate && dateRange?.dateArray[1],
        dateType: lastYear
      })
    }

    if (invoiceDate) {
      const to = dayjs(invoiceDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
      const from = dayjs(invoiceDate, 'DD/MM/YYYY').subtract(60, 'day').format('DD/MM/YYYY')
      payload.activeFilters = createDateFilter({
        from: from,
        to,
        dateType: null
      })
    }
    return payload
  }

  async getDownloadData({
    selectedHierarchy,
    dateRange,
    invoiceDate,
    transactionStatus = 'all-transactions',
    columnSorting = this.columnSorting
  }) {
    const payload = this.getTransactionCallPayload({
      selectedHierarchy,
      dateRange,
      invoiceDate,
      searchTerm: this.searchTerm,
      searchColumnName: this.searchColumnName,
      transactionStatus,
      columnSorting,
      pageSize: MAX_TRANSACTION_COUNT_DOWNLOAD,
      page: 1
    })

    const response = await axios({
      method: 'post',
      url: '/xapi/bp-ds-transactionservice-rio-xapi/api/transactions/external',
      data: payload
    })
    if (response.headers && response.headers['content-type'] === 'gzip') {
      const gzipedData = atob(response.data)
      const gzipedDataArray = Uint8Array.from(gzipedData, c => c.charCodeAt(0))
      const ungzipedData = pako.ungzip(gzipedDataArray)
      let binary = ''
      var bytes = new Uint8Array(ungzipedData)
      // use a for loop to avoid the max stack limit for large responses
      var len = bytes.byteLength
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      return JSON.parse(binary).transactions
    }

    return response.data.transactions
  }

  async getTransactions({
    selectedHierarchy,
    dateRange,
    invoiceDate,
    searchTerm = this.searchTerm,
    searchColumnName = this.searchColumnName,
    transactionStatus = 'all-transactions',
    columnSorting = this.columnSorting,
    page = 1,
    pageSize = this.pageSize,
    forceRefresh
  }) {
    const payload = this.getTransactionCallPayload({
      selectedHierarchy,
      dateRange,
      invoiceDate,
      searchTerm,
      searchColumnName,
      transactionStatus,
      columnSorting,
      pageSize,
      page
    })

    payload.download = { required: 'false' }

    await super.getData({
      method: 'post',
      url: '/xapi/bp-ds-transactionservice-rio-xapi/api/transactions/external',
      payload,
      totalRecordsKey: 'totalNumberOfTransactions',
      responseKey: 'transactions',
      page,
      pageSize,
      searchTerm,
      searchColumnName,
      forceRefresh
    })
  }

  async getSingleTransaction(uniqueId) {
    const productId = +uniqueId.substr(36)
    const payload = {
      transactionUniqueId: uniqueId
    }

    await super.getDetail({
      method: 'get',
      url: `/api/transactions/transactions/${payload.transactionUniqueId.substr(
        0,
        36
      )}?format=v1_summary&productId=${productId}`,
      keyForResult: uniqueId,
      responseKey: 'transaction'
    })
  }

  // not found any references below method that's why commenting

  // getFullTransaction = async uniqueId => {
  //   const payload = {
  //     transactionUniqueId: uniqueId,
  //     download: false
  //   }

  //   await super.getFullDetail({
  //     method: 'post',
  //     url: '/xapi/bp-ds-transactionservice-rio-xapi/api/transactions/transaction/external',
  //     payload,
  //     keyForResult: uniqueId
  //   })
  // }
}

export default TransactionsStore
