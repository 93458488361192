import { action, computed, makeObservable, observable } from 'mobx'
import axios from 'axios'
import qs from 'qs'
import Cookies from 'js-cookie'
import * as get from 'lodash.get'
import getFallbackLanguage, { getLanguageFromUrl } from 'helpers/getFallbackLanguage'

/**
 * Back-end Mulesoft APIs for Rio
 * all share a common sequence and data model
 *
 * This base class offers all of the core re-usable
 * front-end engagement with them that each store can
 * leverage to keep it DRY and simple
 */
class BaseDataStore {
  isFetchingData = true
  responseFailure = null
  currentPage = 1
  pageSize = 15
  searchTerm = ''
  searchColumnName = ''
  keyedLimits = {}
  keyedSearchLimits = {}
  data = null
  dataExpiry = null
  detail = {}
  fullDetail = {}
  defaultColumns = []
  columnSorting = []
  simpleFilter = ''
  filters = []

  constructor(root, sectionName, pageName) {
    this.root = root
    this.sectionName = sectionName
    this.pageName = pageName

    makeObservable(this, {
      isFetchingData: observable,
      responseFailure: observable,
      data: observable,
      detail: observable,
      dataExpiry: observable,
      currentPage: observable,
      pageSize: observable,
      columnSorting: observable,
      searchTerm: observable,
      searchColumnName: observable,
      keyedLimits: observable,
      keyedSearchLimits: observable,
      simpleFilter: observable,
      filters: observable,
      reset: action,
      setData: action,
      setDataExpiry: action,
      setDetail: action,
      setError: action,
      setColumnSorting: action,
      setLimits: action,
      setSearchLimits: action,
      setSearchTerm: action,
      setSearchColumnName: action,
      setSimpleFilter: action,
      setFilters: action,
      setIsFetchingData: action,
      setCurrentPage: action,
      setPageSize: action,
      numberOfPages: computed,
      pageLimits: computed,
      content: computed
    })
  }

  get columnSortingKey() {
    return this.columnSorting.join('-')
  }

  getResultKey({ page = this.currentPage, pageSize = this.pageSize, filters = this.filters } = {}) {
    const selectedHierarchy = this.root.userStore.selectedHierarchy?.accessLevelCode
      ? `${this.root.userStore.selectedHierarchy?.accessLevel}${this.root.userStore.selectedHierarchy?.accessLevelCode}`
      : 'NoAuthority'
    const impersonatedUSer = this.root.userStore.impersonatedUser ? this.root.userStore.impersonatedUser : ''
    const filtersStr = filters ? filters.map(fltr => `_FLT${fltr.filterBy}_${fltr.filterCriteria}_`).join('') : ''
    return `${selectedHierarchy}${impersonatedUSer}${this.simpleFilter}${filtersStr}${this.columnSortingKey}-${page}|${pageSize}`
  }

  getSearchResultKey({ searchTerm = this.searchTerm, columnName = this.searchColumnName } = {}) {
    return `${this.getResultKey()}-search-${searchTerm}-columnName-${columnName}`
  }

  getDataKey() {
    // ***** TODO **** find out why do we need the 2 different sets result and searchResult?
    return this.searchterm ? this.getSearchResultKey() : this.getResultKey()
  }

  get results() {
    const baseKey = this.getResultKey()
    return this.searchResults || this.data?.[baseKey] || []
  }

  get searchResults() {
    const keyForResult = this.getSearchResultKey()
    return this.data?.[keyForResult]
  }

  get numberOfPages() {
    const baseKey = this.getResultKey()
    const { totalRecords } = this.pageLimits?.[baseKey] || this.pageLimits
    if (totalRecords) {
      const pages = Math.ceil(totalRecords / this.pageSize)
      return pages
    }

    return 0
  }

  get pageLimits() {
    // eslint-disable-next-line no-prototype-builtins
    return this.searchLimits.hasOwnProperty('totalRecords') && this.searchResults
      ? this.keyedSearchLimits
      : this.keyedLimits
  }

  get limits() {
    const baseKey = this.getResultKey()
    const retainPagesWhileLoadingNewPage = { totalRecords: this.keyedLimits.totalRecords }
    return this.keyedLimits?.[baseKey] || retainPagesWhileLoadingNewPage
  }

  get searchLimits() {
    const keyForResult = this.getSearchResultKey()
    const retainPagesWhileLoadingNewPage = { totalRecords: this.keyedSearchLimits.totalRecords }
    return this.keyedSearchLimits?.[keyForResult] || retainPagesWhileLoadingNewPage
  }

  get content() {
    const locale = Cookies.get('bpf-locale') || getLanguageFromUrl() || getFallbackLanguage()
    return this.root.contentStore.pageContents[this.pageName + locale]
  }

  get selectedColumns() {
    let preferences = this.root.userStore.preferences?.[this.sectionName]

    if (!preferences || !preferences.length) {
      preferences = this.defaultColumns
    }

    return preferences
  }

  get columns() {
    return (
      this.selectedColumns?.map(preference => ({
        key: preference,
        name: this.content ? this.content[`columnHeading_${preference}`] : '...'
      })) || [{ key: '...', name: '...' }]
    )
  }

  setSimpleFilter(simpleFilter) {
    this.simpleFilter = simpleFilter
  }

  setFilters(filters) {
    this.filters = filters
  }

  setCurrentPage(page) {
    this.currentPage = page
  }

  setPageSize(pageSize) {
    this.pageSize = pageSize
  }

  setColumnSorting(columnSorting) {
    this.columnSorting = columnSorting
  }

  setData(data) {
    this.data = data
  }

  setDataExpiry(dataExpiry) {
    this.dataExpiry = dataExpiry
  }

  setDetail(detail) {
    this.detail = detail
  }

  setFullDetail(detail) {
    this.fullDetail = detail
  }

  setLimits(limitPayload) {
    const baseKey = this.getResultKey()
    this.keyedLimits = {
      ...(this.keyedLimits || {}),
      [baseKey]: limitPayload,
      totalRecords: limitPayload?.totalRecords
    }
  }

  setSearchLimits(limitPayload) {
    const keyForResult = this.getSearchResultKey()
    this.keyedSearchLimits = {
      ...(this.keyedSearchLimits || {}),
      [keyForResult]: limitPayload,
      totalRecords: limitPayload?.totalRecords
    }
  }

  setSearchTerm = searchTerm => {
    this.searchTerm = searchTerm
  }

  setSearchColumnName = searchColumnName => {
    this.searchColumnName = searchColumnName
  }

  setIsFetchingData(isFetchingData) {
    this.isFetchingData = isFetchingData
  }

  setError(errorState) {
    this.responseFailure = errorState
  }

  reset() {
    this.data = null
    this.dataExpiry = null
    this.detail = null
    this.fullDetail = null
    this.searchTerm = ''
    this.keyedLimits = {}
    this.keyedSearchLimits = {}
    this.currentPage = 1
    this.pageSize = 15
  }

  async getAvailableColumns({
    method = 'post',
    url,
    payload,
    availableColumnsKey = '',
    extractNameFromPreferences = false,
    headers = {},
    params = {}
  }) {
    const requestConfig = {
      method,
      url,
      headers
    }

    if (method !== 'get') {
      requestConfig.data = payload
    }

    if (Object.keys(params).length) {
      requestConfig.params = params
    }

    const response = await axios(requestConfig)

    let availableColumns = get(response.data, availableColumnsKey) || []

    if (extractNameFromPreferences) {
      availableColumns = availableColumns.map(({ name }) => name).sort()
    }

    return availableColumns
  }

  async getData({
    method = 'post',
    url,
    payload,
    params,
    searchTerm = '',
    searchColumnName = '',
    page = 1,
    totalRecordsKey = 'totalRecords',
    extraKeysToStore,
    responseKey,
    headers = {},
    forceRefresh = false,
    pageSize = 15,
    filters
  }) {
    this.setCurrentPage(page)
    this.setPageSize(pageSize)
    this.setFilters(filters)
    const baseKey = this.getResultKey({ page, pageSize, filters })
    const searchResultKey = this.getSearchResultKey({ searchTerm, columnName: searchColumnName })
    const keyForResult = searchTerm ? searchResultKey : baseKey

    if (
      forceRefresh ||
      !this.data ||
      !this.data[keyForResult] ||
      this.dataExpiry[keyForResult].getTime() < new Date().getTime()
    ) {
      // if (
      //   forceRefresh ||
      //  !this.dataExpiry ||
      //  !this.dataExpiry[keyForResult] ||
      //  (this.dataExpiry[keyForResult] && this.dataExpiry[keyForResult].getTime() < new Date().getTime())
      //) {
      // setDataExpiry also here for now to remove duplicates calls before response is received -- review when cache key/initial load is refactored
      this.setDataExpiry({
        ...(this.dataExpiry || {}),
        [keyForResult]: new Date(new Date().getTime() + 2 * 60000) // we cache data for 2mn
      })

      this.setError(null)
      this.setIsFetchingData(true)

      try {
        const response = await axios({
          method,
          url,
          data: payload,
          params,
          headers,
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'indices', allowDots: true, encode: false })
          }
        })

        const setLimits = searchTerm ? 'setSearchLimits' : 'setLimits'
        const limitPayload = {
          nextPageLimit: response.data.nextPageLimit,
          nextPageStart: response.data.nextPageStart,
          totalRecords: response.data[totalRecordsKey]
        }

        if (extraKeysToStore) {
          extraKeysToStore.forEach(key => {
            if (response.data[key]) {
              limitPayload[key] = response.data[key]
            }
          })
        }

        this[setLimits](limitPayload)
        const responseData = responseKey ? response.data[responseKey] : response.data

        this.setData({
          ...(this.data || {}),
          [keyForResult]: responseData || []
        })

        this.setDataExpiry({
          ...(this.dataExpiry || {}),
          [keyForResult]: new Date(new Date().getTime() + 2 * 60000) // we cache data for 2mn
        })

        this.setIsFetchingData(false)
      } catch (error) {
        console.error(error)

        this.setData({
          ...(this.data || {})
        })
        this.setError(true)
        this.setIsFetchingData(false)
      }
    }
  }

  async getDetail({ method = 'post', url, payload, responseKey, keyForResult }) {
    if (this.detail[keyForResult]) {
      return
    }
    try {
      const response = await axios({
        method,
        url,
        data: payload
      })

      const responseData = responseKey ? response.data[responseKey] : response.data

      this.setDetail({
        [keyForResult]: responseData,
        ...this.detail
      })
    } catch (error) {
      console.error(error)
    }
  }

  async getFullDetail({ method = 'post', url, payload, keyForResult }) {
    if (this.fullDetail[keyForResult]) {
      return
    }

    const response = await axios({
      method,
      url,
      data: payload
    })

    this.setFullDetail({
      [keyForResult]: response.data,
      ...this.fullDetail
    })
  }
}

export default BaseDataStore
