import { useMemo, useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'
import xlsx from 'json-as-xlsx'
import { format } from 'light-date'
import { Modal } from '@bp-digital/component-modal'
import { Toast } from '@bp-digital/component-toast'
import { TextField } from '@bp-digital/component-text-field'
import { DropDown } from '@bp-digital/component-drop-down'
import { Button } from '@bp-digital/component-button'
import useApiGetTransaction from 'hooks/api/useApiGetTransaction'
import useApiPostTransactionUpdate from 'hooks/api/useApiPostTransactionUpdate'
import getSiteDescription from 'src/helpers/getSiteDescription'
import { TransactionDetailItem, Label, Value } from '../SingleTransactionView/SingleTransactionView.styled'
import { Wrapper, DetailHeader, DetailHeading, DropDownWrapper, Block } from './FullTransactionView.styled'
import { useRootStore } from 'src/contexts/StoreContext'


const CHANGEABLE_ODOMETER = 'changeableOdometer'
const CHANGEABLE_ODOMETER_FLAG = 'cardsOdometerFlag'
const FIELD_LABEL = 'fullDetails_transaction_changeableOdometer'

const FullTransactionView = ({ transactionIds = {}, setTransactionId, selectedHierarchy, content = {} }) => {
  const transactionUniqueId = transactionIds.transactionUniqueId
  const transactionId = transactionIds.transactionId
  const { data, isLoading, error } = useApiGetTransaction(transactionUniqueId, transactionId, content)
  const { userStore :{onefleetmigratedstatus} } = useRootStore()
  const blockedittransaction = (onefleetmigratedstatus > 3)
  const { handleSubmit, reset, setValue, control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      fieldName: CHANGEABLE_ODOMETER,
      fieldValue: undefined
    }
  })

  const [view, setView] = useState('supply')
  const [isEditable, setIsEditable] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [toastData, setToastData] = useState({})

  const setToastMessage = (data, isSuccess) => {
    const randomId = Math.floor(Math.random() * 9999)

    const toastData = {
      id: randomId,
      position: 'center-top',
      autoClose: 5000,
      hasCloseButton: false,
      icon: isSuccess ? 'CheckLargeSolid' : 'RemoveLarge',
      ...data
    }
    setToastData(toastData)
  }

  useMemo(() => {
    const fields = [
      ...(data?.editableFields || []),
      ...(data?.transaction?.filter(({ key }) => key === 'odometer') || [])
    ]
    setIsEditable(fields.find(item => item.key === CHANGEABLE_ODOMETER_FLAG)?.value === 'true')
    return fields
  }, [data])

  const options = [
    { label: content.fullDetails_dropdown_option1_label || 'Invoice view', id: 'invoice' },
    { label: content.fullDetails_dropdown_option2_label || 'Supply view', id: 'supply' },
    { label: content.fullDetails_dropdown_option3_label || 'Issuer view', id: 'issuer' }
  ]

  const handleDownload = () => {
    const formattedForDownload = [
      data.authorisation.map(item => ({ [item.label]: item.value })),
      data.transaction.map(item => ({ [item.label]: item.value })),
      data.customerCard.map(item => ({ [item.label]: item.value })),
      data[`${view}View`].map(item => ({ [item.label]: item.value })),
      data.retailSite.map(item => ({ [item.label]: item.value })),
      data.invoice.map(item => ({ [item.label]: item.value }))
    ]
      .flat()
      .reduce((result, current) => Object.assign(result, current))

    const transactions = {
      sheet: 'Transactions',
      columns: Object.keys(formattedForDownload).map(label => ({ label, value: label })),
      content: [formattedForDownload]
    }

    const date = format(new Date(), '{dd}{MM}{yy}')

    xlsx([transactions], {
      fileName: `Transactions_${selectedHierarchy.accessLevelCode}_${date}`,
      extraLength: 3,
      writeOptions: {}
    })
  }

  const LoadingRow = () => (
    <TransactionDetailItem>
      <Label>
        <Skeleton />
      </Label>
      <Value>
        <Skeleton />
      </Value>
    </TransactionDetailItem>
  )

  const LoadingRows = () => (
    <>
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
    </>
  )

  const downloadAction = {
    text: content?.downloadModal_ctaDownload || 'downloadModal_ctaDownload',
    iconName: 'Download',
    onClick: handleDownload,
    dataAttributes: {
      'data-testid': 'transaction-full-download'
    }
  }

  const closeAction = {
    text: content?.fullDetails_closeConfirmClose || 'fullDetails_closeConfirmClose',
    iconName: 'Close',
    onChange: () => setTransactionId(null),
    dataAttributes: {
      'data-testid': 'transaction-close-full-view'
    }
  }

  const elementRef = useRef()

  const onSubmitSuccess = () => {
    reset()
    setIsEditing(false)
    setIsSubmitting(false)
    setToastMessage(
      {
        title: content?.transactionStatus_successful || 'transactionStatus_successful',
        content: content?.fullDetails_transaction_detailUpdatedSuccess.replace('{{fieldLabel}}', content?.[FIELD_LABEL])
      },
      true
    )
  }

  const onSubmitError = () => {
    setIsSubmitting(false)
    setToastMessage(
      {
        title: content?.try_again_text || 'try_again_text',
        content: content?.fullDetails_transaction_detailUpdatedError.replace('{{fieldLabel}}', content?.[FIELD_LABEL])
      },
      false
    )
  }

  const { mutate: submitForm } = useApiPostTransactionUpdate(transactionId, onSubmitSuccess, onSubmitError)

  const onSubmit = formData => {
    setIsSubmitting(true)
    submitForm({ ...formData, transactionId })
  }

  const sanitizeValue = value => {
    // eliminate undefined and null
    value = value ?? ''
    const isEmptyString = typeof value == 'string' && value.length == 0
    if (isEmptyString) return '-'

    // isNaN is used vs Number.isNaN to ensure conversion of strings like 'EUR' to number type in order to differentiate between valid and invalid strings for parseFloat
    const isNaNValue = isNaN(value)
    if (isNaNValue) return value

    return parseFloat(value, 10).toFixed(2)
  }

  const getLocalisedValue = (label, value) => {
    if (typeof value !== String) return value
    const codedLabel = label?.replace(/\s/g, '_').toLowerCase()
    const codedValue = value?.replace(/\s/g, '_').toLowerCase()
    return content?.[`fullDetails_${codedLabel}_${codedValue}`] || value
  }

  return (
    <>
      <Modal
        visible={!!transactionId}
        onDismiss={() => setTransactionId(null)}
        title={content?.fullDetails_header || 'fullDetails_header'}
        primaryAction={error ? closeAction : downloadAction}
        secondaryAction={
          error || blockedittransaction
            ? undefined
            : {
                text: `${content?.fullDetails_editDetailsButton}`,
                iconName: 'Edit',
                appearance: 'tertiary',
                disabled: !isEditable,
                onClick: () => {
                  setIsEditing(prevState => !prevState)
                  elementRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
                },
                dataAttributes: { 'data-testid': 'edit-transaction-details' }
              }
        }
        size='lg'
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Wrapper>
            <Block data-testid='section-authorisation'>
              <DetailHeader>
                <DetailHeading>{content.fullDetails_authorisation_title || '...'}</DetailHeading>
              </DetailHeader>
              {isLoading && <LoadingRows />}
              {data &&
                data.authorisation.map(item => (
                  <TransactionDetailItem key={item.key}>
                    <Label>{item.label}</Label>
                    <Value>{getLocalisedValue(item.label, item.value)}</Value>
                  </TransactionDetailItem>
                ))}
            </Block>
            <Block data-testid={`section-${view}`}>
              <DetailHeader $hasDropdown>
                <DetailHeading>{content.fullDetails_supplyView_title || '...'}</DetailHeading>
                <DropDownWrapper>
                  <DropDown
                    id='view-toggle'
                    options={options}
                    onChange={view => setView(view)}
                    defaultSelectedId={view}
                  />
                </DropDownWrapper>
              </DetailHeader>
              {isLoading && <LoadingRows />}
              {data &&
                data[`${view}View`].map(item => (
                  <TransactionDetailItem key={item.key}>
                    <Label>{item.label}</Label>
                    <Value>{getLocalisedValue(item.label, sanitizeValue(item.value))}</Value>
                  </TransactionDetailItem>
                ))}
            </Block>
            <Block data-testid='section-transaction'>
              <DetailHeader>
                <DetailHeading>{content.fullDetails_transaction_title || '...'}</DetailHeading>
              </DetailHeader>
              {isLoading && <LoadingRows />}
              {data &&
                data.transaction.map(item => {
                  const targetItem = item.key === CHANGEABLE_ODOMETER
                  if (targetItem) setValue('fieldValue', item.value)

                  return (
                    <TransactionDetailItem key={item.key} ref={targetItem ? elementRef : null}>
                      <Label>{item.label}</Label>

                      {isEditing && targetItem ? (
                        <>
                          <Controller
                            name='fieldValue'
                            control={control}
                            rules={{
                              maxLength: {
                                value: 7,
                                message: content?.advancedFilters_validation_maxDigits?.replace('{{number}}', 7)
                              },
                              pattern: {
                                value: /^\d+$/,
                                message:
                                  content?.advancedFilters_validation_integer || 'advancedFilters_validation_integer'
                              }
                            }}
                            render={({ field: { onChange }, fieldState: { error } }) => (
                              <TextField
                                autoFocus={true}
                                defaultValue={item.value}
                                textChangeHandler={onChange}
                                error={error}
                                errorMessage={error?.message}
                              />
                            )}
                          />
                          <Button
                            onClick={() => {
                              setIsEditing(false)
                              setValue('fieldValue', undefined)
                              setToastData(null)
                            }}
                            disabled={isSubmitting}
                            dataAttributes={{ 'data-content-key': 'editDetails_cancel' }}
                          >
                            {content?.editDetails_cancel}
                          </Button>
                          <Button
                            iconName='Save'
                            type='submit'
                            isLoading={isSubmitting}
                            dataAttributes={{
                              'data-content-key': 'editDetails_save',
                              'data-testid': 'save-odometer-change'
                            }}
                          >
                            {content?.editDetails_save}
                          </Button>
                        </>
                      ) : (
                        <Value>{getLocalisedValue(item.label, item.value)}</Value>
                      )}
                    </TransactionDetailItem>
                  )
                })}
            </Block>
            <Block data-testid='section-card'>
              <DetailHeader>
                <DetailHeading>{content.fullDetails_customerCard_title || '...'}</DetailHeading>
              </DetailHeader>
              {isLoading && <LoadingRows />}
              {data &&
                data.customerCard.map(item => (
                  <TransactionDetailItem key={item.key}>
                    <Label>{item.label}</Label>
                    <Value>{getLocalisedValue(item.label, item.value)}</Value>
                  </TransactionDetailItem>
                ))}
            </Block>
            <Block data-testid='section-invoice'>
              <DetailHeader>
                <DetailHeading>{content.fullDetails_invoice_title || '...'}</DetailHeading>
              </DetailHeader>
              {isLoading && <LoadingRows />}
              {data &&
                data.invoice.map(item => (
                  <TransactionDetailItem key={item.key}>
                    <Label>{item.label}</Label>
                    <Value>{getLocalisedValue(item.label, item.value)}</Value>
                  </TransactionDetailItem>
                ))}
            </Block>
            <Block data-testid='section-retail-site'>
              <DetailHeader>
                <DetailHeading>{content.fullDetails_retailSite_title || '...'}</DetailHeading>
              </DetailHeader>
              {isLoading && <LoadingRows />}
              {data &&
                data.retailSite.map(item => (
                  <TransactionDetailItem key={item.key}>
                    <Label>{item.label}</Label>
                    <Value>
                      {item.key === 'siteTypeDescription'
                        ? getSiteDescription(item.value)
                        : getLocalisedValue(item.label, item.value)}
                    </Value>
                  </TransactionDetailItem>
                ))}
            </Block>
          </Wrapper>
        </form>
      </Modal>
      <Toast appearance='dark' toast={toastData} />
    </>
  )
}

export default FullTransactionView
