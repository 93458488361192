import axios, { AxiosError } from 'axios'
import { useMutation } from 'react-query'



const useApiPostStaticFile = (pricelistfromAzure: boolean) =>
  useMutation(async form => {
    try {
      let url = '/api/pricelist'
      if (pricelistfromAzure) {
        url = '/api/transactions/pricingList/UploadAsync'
      }

      const result = await axios.post(url, form)
      return result
    } catch (e) {
      const error = e as AxiosError
      throw {
        error: error.response?.data,
        message: error.message
      }
    }
  })

export default useApiPostStaticFile
