import { useCallback, useEffect, useState, useMemo } from 'react'
import { useHistory, Redirect } from 'react-router'
import { observer } from 'mobx-react'
import Skeleton from 'react-loading-skeleton'
import { Icon } from '@bp-digital/component-icon'
import debounce from 'lodash.debounce'
import PageHeader from 'components/layout/PageHeader'
import InnerPageWrapper from 'components/layout/InnerPageWrapper'
import PreferencesEditor from 'src/components/dataDisplay/PreferencesEditor'
import { useRootStore } from 'contexts/StoreContext'
import { ROUTE_CARDS_RESTRICTIONS, ROUTE_CARDS_RESTRICTIONS_NEW, ROUTE_ADMIN } from 'constants/routes'
import replaceVariablesInString from 'helpers/replaceVariablesInString'
import { ReactTable } from 'components/dataDisplay/ReactTable/Table'
import useApiGetProfileOverrides from 'hooks/api/useApiGetProfileOverrides'
import OverrideProfiles from './components/OverrideProfiles'
import OverrideAlert from './components/OverrideAlert'
import SetBaseProfile from './components/SetBaseProfile'

const YES = 'Y'
const NO = 'N'

const CardsRestrictionsPage = () => {
  const history = useHistory()
  const { cardsRestrictionsStore, contentStore, userStore } = useRootStore()
  const [isEditingColumns, setIsEditingColumns] = useState(false)
  const [isOverridingProfiles, setIsOverridingProfiles] = useState(false)
  const [isSettingBaseProfile, setIsSettingBaseProfile] = useState(false)
  const authorityId = userStore.selectedAuthorityId
  const content = contentStore.getPage('cards-restrictions')
  const { data: overrides = [] } = useApiGetProfileOverrides(authorityId)
  const loadingRow = cardsRestrictionsStore.columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )

  useEffect(() => {
    return () => cardsRestrictionsStore.setSearchTerm('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!content) {
      contentStore.getContent('cards-restrictions')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  useEffect(() => {
    if (authorityId) {
      cardsRestrictionsStore.getRestrictions({ authorityId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorityId])

  const rowItemTransform = item => {
    for (const [key, value] of Object.entries(item)) {
      if (value === YES) item[key] = <Icon name='CheckLargeSolid' state='success' appearance='dark' />
      else if (value === NO) item[key] = <Icon name='DeleteSmall' />
    }
    return item
  }

  const handleSubmitSearch = async () => {
    await cardsRestrictionsStore.getRestrictions({ authorityId })
  }

  const handleChangeSearch = searchTerm => {
    debouncedChange(searchTerm)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce(searchTerm => {
      cardsRestrictionsStore.setSearchTerm(searchTerm)
    }, 100),
    [handleChangeSearch]
  )

  const handleClearSearch = () => {
    cardsRestrictionsStore.setSearchTerm('')
    cardsRestrictionsStore.setSearchLimits({})
    cardsRestrictionsStore.getRestrictions({ authorityId })
  }

  const handleRowClick = useCallback(
    (_event, { profileId, profileName, createdDate, lastUpdatedDate, customerId, parentId, noOfCards, ...rest }) => {
      if (!profileId) return

      history.push(`${ROUTE_CARDS_RESTRICTIONS}/${profileId}`, {
        profileName,
        createdDate,
        lastUpdatedDate,
        authorityId,
        profile: {
          authorityId: rest.authorityId,
          customerId,
          parentId,
          noOfCards
        }
      })
    },
    [authorityId, history]
  )

  const tableSearchProps = {
    searchTerm: cardsRestrictionsStore.searchTerm,
    onChange: handleChangeSearch,
    onSubmit: handleSubmitSearch,
    onClear: handleClearSearch,
    placeholder: content?.search_placeholder || '...',
    inputAriaLabel: content?.search_placeholder || '...',
    clearAriaLabel: 'Clear input',
    submitAriaLabel: 'Submit search'
  }

  // const getProfilesByIds = (profileIds = []) => {
  //   const rawData = toJS(cardsRestrictionsStore.data)
  //   const profilePages = Object.values(rawData)
  //   const columns = cardsRestrictionsStore.columns

  //   const profiles = profileIds
  //     .map(id => {
  //       return profilePages
  //         .map(page => {
  //           const profile = page.find(profile => String(profile.profileId) === id)
  //           return profile
  //         })
  //         .find(val => !!val)
  //     })
  //     .filter(value => !!value)

  //   const profilesWithContent = profiles.map(transaction => {
  //     const renamedTransaction = {}
  //     const keys = Object.keys(transaction)

  //     keys.forEach(originalKey => {
  //       const friendlyName = columns.find(column => column.key === originalKey)?.name || originalKey
  //       renamedTransaction[friendlyName] = transaction[originalKey]
  //     })

  //     return renamedTransaction
  //   })

  //   return {
  //     sheet: 'Profiles',
  //     columns: columns.map(col => ({ label: col.name, value: col.name })),
  //     content: profilesWithContent
  //   }
  // }

  const tableActionProps = [
    {
      text: content?.restriction_profiles_create_profile || 'Create Profile',
      size: 'lg',
      onClick: () => {
        history.push(ROUTE_CARDS_RESTRICTIONS_NEW)
      },
      dataAttributes: {
        'data-testid': 'button-new-profile'
      }
    },
    // {
    //   text: content?.restriction_profiles_override_profile || 'Override profiles',
    //   size: 'lg',
    //   onClick: () => {
    //     setIsOverridingProfiles(true)
    //   },
    //   dataAttributes: {
    //     'data-testid': 'button-override-profiles'
    //   }
    // },
    {
      text: content?.restriction_profiles_set_base_profiles || 'Set base profile',
      size: 'lg',
      onClick: () => {
        setIsSettingBaseProfile(true)
      },
      disabled: !cardsRestrictionsStore.data || !cardsRestrictionsStore.rows.length,
      dataAttributes: {
        'data-testid': 'button-set-base-profile'
      }
    },
    {
      text: content?.tableFilter_ctaColumn || 'Columns',
      iconName: 'Edit',
      size: 'lg',
      disabled: cardsRestrictionsStore.isFetchingData,
      onClick: () => {
        setIsEditingColumns(true)
      },
      dataAttributes: {
        'data-testid': 'button-edit-columns'
      }
    }
    // {
    //   text: content?.restriction_profiles_download_profiles || 'Download',
    //   iconName: 'Download',
    //   size: 'lg',
    //   enableOnSelection: true,
    //   onClick: selectedRows => {
    //     console.info(selectedRows)
    //     const transactions = getProfilesByIds(selectedRows)
    //     const date = format(new Date(), '{dd}{MM}{yy}')

    //     xlsx([transactions], {
    //       fileName: `${content?.restriction_profiles_hero_title}_${authorityId}_${date}`,
    //       extraLength: 3,
    //       writeOptions: {}
    //     })
    //   },
    //   dataAttributes: {
    //     'data-testid': 'button-download'
    //   }
    // }
  ]

  const tablePagination = {
    currentPage: cardsRestrictionsStore.currentPage,
    count: cardsRestrictionsStore.numberOfPages || 1,
    disabled: !cardsRestrictionsStore.data,
    onChange: value => cardsRestrictionsStore.getRestrictions({ authorityId, page: value })
  }

  const handlePreferenceChange = async newlySelectedColumns => {
    await userStore.updatePreferences('restrictions', newlySelectedColumns)
    await userStore.getPreferences()
    await cardsRestrictionsStore.getRestrictions({ authorityId, forceRefresh: true })
  }

  const data = useMemo(
    () =>
      cardsRestrictionsStore.isFetchingData
        ? Array(15)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : cardsRestrictionsStore.rows.map(rowItemTransform),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardsRestrictionsStore.rows, cardsRestrictionsStore.isFetchingData, tablePagination.currentPage]
  )

  const columns = useMemo(
    () =>
      cardsRestrictionsStore.isFetchingData
        ? cardsRestrictionsStore.columns.map(col => ({
            Header: col.name,
            accessor: col.key,
            Cell: <Skeleton width={100} />
          }))
        : cardsRestrictionsStore.columns.map(col => {
            return {
              Header: col.name,
              accessor: col.key
            }
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardsRestrictionsStore.columns, cardsRestrictionsStore.isFetchingData, tablePagination.currentPage]
  )

  const handleSort = useCallback(
    (key, desc) => {
      const sortRestrictions = async (key = 'profileName', desc = true) => {
        const columnSorting = [key, desc ? 'desc' : 'asc']
        cardsRestrictionsStore.setColumnSorting(columnSorting)
        // Get base results of sorted table state to display as fallback
        // prevents no cached results bug when backspacing sorted search results
        if (!cardsRestrictionsStore.results.length && cardsRestrictionsStore.searchTerm.length) {
          await cardsRestrictionsStore.getRestrictions({
            authorityId,
            columnSorting,
            searchTerm: ''
          })
        }
        await cardsRestrictionsStore.getRestrictions({
          authorityId,
          columnSorting
        })
      }
      if (cardsRestrictionsStore.rows.length > 0) {
        sortRestrictions(key, desc)
      }
    },
    [authorityId, cardsRestrictionsStore]
  )

  if (userStore.isAdmin && !userStore.impersonatedUser) return <Redirect to={ROUTE_ADMIN} />

  return (
    <>
      <PageHeader
        title={content?.restriction_profiles_hero_title || '...'}
        subtitle={replaceVariablesInString(content?.restriction_profiles_showing || '...', {
          amount: cardsRestrictionsStore.rows.length,
          totalProfiles: cardsRestrictionsStore.limits?.totalRecords || '0'
        })}
        brand={userStore.brand}
      />
      <InnerPageWrapper>
        {isOverridingProfiles && (
          <OverrideProfiles
            content={content}
            onClose={() => setIsOverridingProfiles(false)}
            authorityId={authorityId}
            customerId={userStore.selectedAuthority?.customerId}
            authorityName={userStore.selectedAuthority?.authorityName}
          />
        )}
        {isSettingBaseProfile && (
          <SetBaseProfile content={content} onClose={() => setIsSettingBaseProfile(false)} authorityId={authorityId} />
        )}
        {content && isEditingColumns && (
          <PreferencesEditor
            initialSelectedColumns={cardsRestrictionsStore.selectedColumns}
            setIsOpen={setIsEditingColumns}
            onUpdate={handlePreferenceChange}
            sectionName='restrictions'
            getAvailableColumns={() => cardsRestrictionsStore.getAvailableColumns(authorityId)}
            content={content}
            locale={userStore.locale}
          />
        )}
        {!!overrides && !!overrides.length && (
          <OverrideAlert
            content={content}
            override={overrides[0]} // single authority scope, only one possible (?)
          />
        )}
        <ReactTable
          name='restrictions'
          columns={columns}
          data={data}
          pagination={tablePagination}
          search={tableSearchProps}
          actions={tableActionProps}
          hideCheckboxColumn
          onSort={handleSort}
          handleRowClick={handleRowClick}
          isLoading={cardsRestrictionsStore.isFetchingData}
        />
      </InnerPageWrapper>
    </>
  )
}

export default observer(CardsRestrictionsPage)
