import { useState, useEffect } from 'react'

import Paper from 'components/surfaces/Paper'
import TwoColumn from 'components/layout/TwoColumn'
import { TextField } from '@bp-digital/component-text-field'
import Skeleton from 'react-loading-skeleton'
//import { Button } from '@bp-digital/component-button'
import { PhoneField } from '@bp-digital/component-phone-field'
//import styled from 'styled-components'

// const ButtonWrapper = styled.div`
//   display: flex;
//   align-items: flex-end;
//   padding-bottom: var(--structure-spacing-md);
const ignoredCharsRegEx = /[\\="<>/:;]/g

const AccountDetails = ({
  title,
  showUserName = true,
  userNameLabel,
  firstNameLabel,
  lastNameLabel,
  jobTitleLabel,
  emailLabel,
  mobileNumberLabel,
  landlineNumberLabel,
  //changePasswordLabel = 'Change password',
  countryList,
  setUserDetails,
  user,
  button
  //onChangePassword
}) => {
  const [firstName, setFirstName] = useState(user?.firstName || '')
  const [lastName, setLastName] = useState(user?.lastName || '')
  const [jobTitle, setJobTitle] = useState(user?.jobTitle || '')

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setJobTitle(user.jobTitle)
    }
  }, [user])

  return (
    <Paper title={title || '...'} button={button}>
      {user ? (
        <>
          {showUserName && (
            <TwoColumn>
              <TextField
                label={userNameLabel || '...'}
                name='userName'
                defaultValue={user.userName}
                disabled
                readOnly
              />
              {/* <ButtonWrapper>
                <Button appearance='secondary' iconName='Security' onClick={onChangePassword}>
                  {changePasswordLabel}
                </Button>
              </ButtonWrapper> */}
            </TwoColumn>
          )}
          <TwoColumn>
            <TextField
              label={firstNameLabel || '...'}
              name='firstName'
              value={firstName}
              onChange={value => {
                const sanitizedValue = value.replaceAll(ignoredCharsRegEx, '')
                setFirstName(sanitizedValue)
                setUserDetails([{ firstName: sanitizedValue }])
              }}
              textChangeHandler={value => setUserDetails(details => ({ ...details, firstName: value }))}
              required
            />
            <TextField
              label={lastNameLabel || '...'}
              name='lastName'
              value={lastName}
              onChange={value => {
                const sanitizedValue = value.replaceAll(ignoredCharsRegEx, '')
                setLastName(sanitizedValue)
                setUserDetails([{ lastName: sanitizedValue }])
              }}
              textChangeHandler={value => setUserDetails(details => ({ ...details, lastName: value }))}
              required
            />
            <TextField
              label={jobTitleLabel || '...'}
              name='jobTitle'
              value={jobTitle}
              onChange={value => {
                const sanitizedValue = value.replaceAll(ignoredCharsRegEx, '')
                setJobTitle(sanitizedValue)
                setUserDetails([{ jobTitle: sanitizedValue }])
              }}
              textChangeHandler={value => setUserDetails(details => ({ ...details, jobTitle: value }))}
            />
            <TextField label={emailLabel || '...'} name='email' defaultValue={user.email} disabled readOnly />
            <PhoneField
              label={mobileNumberLabel || '...'}
              prefixItems={countryList}
              onChange={value =>
                setUserDetails([{ mobileNumberIsdCode: value.prefix }, { mobileNumber: value.number }])
              }
              defaultPrefix={user.mobileNumberIsdCode}
              defaultValue={user.mobileNumber}
              maxLength={10}
            />
            <PhoneField
              label={landlineNumberLabel || '...'}
              prefixItems={countryList}
              onChange={value =>
                setUserDetails([{ landlineNumberIsdCode: value.prefix }, { landlineNumber: value.number }])
              }
              defaultPrefix={user.landlineNumberIsdCode}
              defaultValue={user.landlineNumber}
              maxLength={10}
            />
          </TwoColumn>
        </>
      ) : (
        <>
          {showUserName && (
            <TwoColumn>
              <Skeleton height={70} />
              <Skeleton height={70} />
            </TwoColumn>
          )}
          <TwoColumn>
            <Skeleton count={3} height={70} style={{ margin: '8px 0' }} />
            <Skeleton count={3} height={70} style={{ margin: '8px 0' }} />
          </TwoColumn>
        </>
      )}
    </Paper>
  )
}

export default AccountDetails
