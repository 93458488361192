import { getLanguageFromUrl } from 'helpers/getFallbackLanguage'
import { getCodeChallengeandCodeVerifier } from 'helpers/getCodeVerifier_Challenge'
import axios from 'axios'
import Cookies from 'js-cookie'
import { EXPIRY_ID, IS_ADMIN, IDP_TOKEN, IDP_REFRESH } from 'constants/localStorage'
import { getClientvalue, getForgeRockBase } from './getForgeRockEnvValues'
import crypto from "crypto"

export async function getCIPLoginPath() {
  const redirectUrl = window.location.origin
  const baseOIDCUrl = getForgeRockBase()
  const locale = getLanguageFromUrl()
  const oidcURL = `${baseOIDCUrl}/am/oauth2/authorize`
  const clientId = getClientvalue()
  const queryParams = [`client_id=${clientId}`]
  const data = await getCodeChallengeandCodeVerifier()
  const localStorage = window.localStorage
  localStorage.setItem('code_verifier', data.code_verifier)
  const nounce = crypto.randomBytes(16).toString("base64");
  const codeChallenge = data.code_challenge
  queryParams.push(`code_challenge=${codeChallenge}`)
  queryParams.push(`redirect_uri=${redirectUrl}`)
  queryParams.push(`code_challenge_method=S256`)
  queryParams.push(`response_type=code`)
  queryParams.push(`scope=openid email b2b-profile`)
  queryParams.push(`nonce=${nounce}`)
  queryParams.push(`locale=${locale}`)
  const res = `${oidcURL}?${queryParams.join('&')}`
  return res.toString()
}
export async function getCIPLogoutPath() {
  const redirectUrl = window.location.origin
  const brand = Cookies.get('brandfromdomain')
  await axios.post(
     `/api/users/token/gettokens?logout_action=logout`,
     {  brand:brand ,rtoken: localStorage.getItem(IDP_REFRESH)
       //idtoken:localStorage.getItem(IDP_IDToken)
      },
     {
       skipAuthRefresh: true,
       withCredentials: true
     }
   )
  Cookies.remove('bpf-refresh-expiry')
  Cookies.remove('bpf-brand')
  Cookies.remove('bpf-imp')
  Cookies.remove('bpf-idp')
  localStorage.removeItem('code_verifier')
  removeItemsFromLocalStorage([IS_ADMIN, EXPIRY_ID, IDP_TOKEN, IDP_REFRESH])
  return redirectUrl
}
export const removeItemsFromLocalStorage = (keys: string[]) => {
  keys.forEach(key => localStorage.removeItem(key))
}
