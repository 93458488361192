import ReactDOM from 'react-dom'
import { Dispatch, SetStateAction, useContext, useState } from 'react'
import { Modal } from '@bp-digital/component-modal'
import { Text } from '@bp-digital/component-typography'
import { Popover } from '@bp-digital/component-popover'
import { AccordionPanel } from '@bp-digital/component-accordion-panel'
import { Icon } from '@bp-digital/component-icon'
import { Chip } from '@bp-digital/component-chip'
import { AppliedFilters, FiltersContainer, InfoWrapper } from './InvoiceArchiveFilters.styled'
import { Spacer } from 'styles/common.styled'
import DateRangeFilter, { FILTER_NAME as INVOICE_DATE_FILTER_NAME } from './Filters/DateRangeFilter'
import InvoiceTypeFilter, { FILTER_NAME as INVOICE_TYPE_FILTER_NAME } from './Filters/InvoiceTypeFilter'
import InvoiceNumberFilter from './Filters/InvoiceNumberFilter'
import InvoiceSupplyCountryFilter, {
  FILTER_NAME as INVOICE_SUPPLY_COUNTRY_FILTER
} from './Filters/InvoiceSupplyCountryFilter'
import InvoiceAmountFilter, { FILTER_NAME as INVOICE_AMOUNT_FILTER } from './Filters/InvoiceAmountFilter'
import { FilterContext } from '../../ArchivedInvoicesPage'
import { getFilterText } from '../../helpers/getAllAppliedFilterBadges'
import { filterTypeAndNameMatch } from '../../helpers/getAllAppliedFilterBadges/getFilterText'
import { InvoiceFilterType } from '../../ArchivedInvoicesPage'

export interface InvoiceFilterProps {
  content: { [key: string]: string }
  setRemoveFilterId: Dispatch<SetStateAction<string[]>>
  removedFilterIds: string[]
  removeSupplyCountryFilter?: string
}

const root = document.getElementById('root') as HTMLElement

const InvoiceArchiveFilters = ({
  content = {},
  setIsRequestReady,
  onClose,
  setCurrentActiveFilters,
  removedSupplyCountry,
  setRemovedSupplyCountry
}: {
  content: { [key: string]: string }
  setIsRequestReady: Dispatch<SetStateAction<boolean>>
  onClose: () => void
  setCurrentActiveFilters: Dispatch<SetStateAction<InvoiceFilterType[]>>
  removedSupplyCountry: string
  setRemovedSupplyCountry: Dispatch<SetStateAction<string>>
}) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const { filterData, setFilterData } = useContext(FilterContext)

  const [removedFilterIds, setRemoveFilterId] = useState<string[]>([])

  const removeFilter = (type: string) => {
    return () => {
      setRemoveFilterId(ids => [...ids, type])
      setFilterData(filters => filters.filter(item => item.type !== type))
    }
  }

  const clearFilters = () => {
    setRemoveFilterId([
      INVOICE_DATE_FILTER_NAME,
      INVOICE_TYPE_FILTER_NAME,
      INVOICE_SUPPLY_COUNTRY_FILTER,
      INVOICE_AMOUNT_FILTER
    ])
    setIsRequestReady(false)
    setFilterData([])
  }

  const handleOnDismiss = () => {
    if (!isUpdating) onClose()
  }

  return ReactDOM.createPortal(
    <Modal
      title={content?.advancedFilters_header ?? 'advancedFilters_header'}
      primaryAction={{
        text: content?.advancedFilters_apply || 'advancedFilters_apply',
        onClick: () => {
          setCurrentActiveFilters(filterData)
          setIsUpdating(true)
          setIsRequestReady(true)
        },
        iconName: 'Save',
        disabled: isUpdating || !filterData.length,
        isLoading: isUpdating
      }}
      secondaryAction={{
        text: content?.advancedFilters_clear || 'advancedFilters_clear',
        onClick: clearFilters,
        iconName: 'RemoveLarge',
        appearance: 'tertiary',
        disabled: isUpdating || !filterData.length
      }}
      onDismiss={handleOnDismiss}
      visible
      size='lg'
    >
      <InfoWrapper>
        <Text>{content?.advancedInvoiceArchive_title ?? 'advancedInvoiceArchive_title'}</Text>

        <Popover
          title={''}
          text={content?.advanceInvoiceArchive_info ?? 'advanceInvoiceArchive_info'}
          appearance='dark'
          position='bottom'
        >
          <Icon name='Info' appearance='dark' size='md' state='neutral' />
        </Popover>
      </InfoWrapper>

      <Text>{content?.archive_invoice_download_info_simple ?? 'archive_invoice_download_info_simple'}</Text>

      <Spacer />

      <FiltersContainer>
        <AccordionPanel
          title={content?.advancedFilters_filterName_invoiceDate}
          iconName='Date'
          iconState='neutral'
          iconAppearance='dark'
          borderState='dark'
        >
          <DateRangeFilter
            content={content}
            removedFilterIds={removedFilterIds}
            setRemoveFilterId={setRemoveFilterId}
          />
        </AccordionPanel>

        <AccordionPanel
          title={content?.advancedFilters_filterName_invoiceType}
          iconName='Select'
          iconState='neutral'
          iconAppearance='dark'
          borderState='dark'
        >
          <InvoiceTypeFilter
            content={content}
            removedFilterIds={removedFilterIds}
            setRemoveFilterId={setRemoveFilterId}
          />
        </AccordionPanel>

        <AccordionPanel
          title={content?.advancedFilters_filterName_invoiceNumber}
          iconName='SpreadsheetGeneric'
          iconState='neutral'
          iconAppearance='dark'
          borderState='dark'
        >
          <InvoiceNumberFilter
            content={content}
            removedFilterIds={removedFilterIds}
            setRemoveFilterId={setRemoveFilterId}
          />
        </AccordionPanel>

        <AccordionPanel
          title={content?.advancedFilters_filterName_supplyCountry}
          iconName='Flag'
          iconState='neutral'
          iconAppearance='dark'
          borderState='dark'
        >
          <InvoiceSupplyCountryFilter
            content={content}
            removedFilterIds={removedFilterIds}
            setRemoveFilterId={setRemoveFilterId}
            removeSupplyCountryFilter={removedSupplyCountry}
          />
        </AccordionPanel>

        <AccordionPanel
          title={content?.advancedFilters_filterName_amount || 'advancedFilters_filterName_amount'}
          iconName='CvcCvv'
          iconState='neutral'
          iconAppearance='dark'
          borderState='dark'
        >
          <InvoiceAmountFilter content={content} removedFilterIds={removedFilterIds} />
        </AccordionPanel>
      </FiltersContainer>

      <AppliedFilters>
        {filterData.map(filter => {
          if (filter.type === 'supplyCountry') {
            return filter.payload.keyGroup[0].values.map(({ countryCode }) => {
              return (
                <Chip
                  key={`${filter.type}-${countryCode}`}
                  iconName={filterTypeAndNameMatch.get(filter.type)?.iconName}
                  text={`${content['advancedFilters_filterName_supplyCountry']}: ${
                    content[`advancedFilters_${countryCode}`]
                  }`}
                  state='success'
                  appearance='primary'
                  onClick={() => setRemovedSupplyCountry(String(countryCode))}
                />
              )
            })
          } else {
            return (
              <Chip
                key={filter.text}
                iconName={filterTypeAndNameMatch.get(filter.type)?.iconName}
                text={getFilterText(filter.payload, content)}
                state='success'
                appearance='primary'
                onClick={removeFilter(filter.type)}
              />
            )
          }
        })}
      </AppliedFilters>
    </Modal>,
    root
  )
}

export default InvoiceArchiveFilters
