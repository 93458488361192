import { observer } from 'mobx-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'
import { useRootStore } from 'src/contexts/StoreContext'
import PageHeader from 'src/components/layout/PageHeader'
import InnerPageWrapper from 'src/components/layout/InnerPageWrapper'
import { ROUTE_DASHBOARD, ROUTE_LOGOUT } from 'src/constants/routes'
import { SearchBox } from '@bp-digital/component-search-box'
import {
  AdminMenuContainer,
  SingleUserViewWrapper,
  SingleUserRawDataWrapper,
  SingleUserMainDataWrapper,
  NoDataWrapper,
  ControlsWrapper,
  SearchWithTooltipWrapper,
  LinkWrapper
} from './AdminPage.style'
import AdminDecisionPanel from './components/AdminDecisionPanel/AdminDecisionPanel'
import { Button } from '@bp-digital/component-button'
import { Redirect, Link } from 'react-router-dom'
import { ReactTable } from 'components/dataDisplay/ReactTable/Table'
import Skeleton from 'react-loading-skeleton'
//import { getSalesforcePasswordUrl } from 'helpers/getSalesForceValue'
//import getBrandFromDomain from 'helpers/getBrandFromDomain'
import { Icon } from '@bp-digital/component-icon'
import { useHistory } from 'react-router-dom'

const AdminPage = () => {
  const { adminStore, userStore, contentStore } = useRootStore()
  const content = contentStore.getPage('access-users')

  adminStore.setAdminView(true)

  const [displayModal, setdisplayModal] = useState(true)
  const [isAuthoritySearch, setIsAuthoritySearch] = useState(false)
  const history = useHistory()
  const loadingRow = adminStore.columns.reduce(
    (prev, col) => ({
      ...prev,
      [col.key]: <Skeleton width={100} />
    }),
    {}
  )

  useEffect(() => {
    adminStore.setSearchTerm('')
    adminStore.getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!content) {
      contentStore.getContent('access-users')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  // below is for page reload - to solve the local storage key problem and lazy loadng of selectedHierarchy
  useEffect(() => {
    adminStore.getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.selectedHierarchy])

  const handleSubmitSearch = async () => {
    await adminStore.getUsers()

    setdisplayModal(true)
  }

  const handleChangeSearch = searchTerm => {
    debouncedChange(searchTerm)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce(searchTerm => {
      let authIdRegEx = new RegExp('^[A-Za-z]?[0-9]{3,7}$')
      setIsAuthoritySearch(authIdRegEx.test(searchTerm))
      adminStore.setSearchTerm(searchTerm)
    }, 100),
    [handleChangeSearch]
  )

  const handleClearSearch = () => {
    adminStore.setSearchTerm('')
    adminStore.setSearchLimits({})
    adminStore.getUsers()
  }

  const logout = async () => {
    window.isLoggingOut = true
    history.push(ROUTE_LOGOUT)
  }

  const tableSearchProps = {
    searchTerm: adminStore.searchTerm,
    minLength: 4,
    onChange: handleChangeSearch,
    onSubmit: handleSubmitSearch,
    onClear: handleClearSearch,
    placeholder: 'Authority Id or User',
    inputAriaLabel: content?.search_users || '...',
    clearAriaLabel: 'Clear input',
    submitAriaLabel: 'Submit search'
  }

  const tableActionProps = []

  const tablePagination = {
    currentPage: adminStore.currentPage,
    count: adminStore.numberOfPages || 1,
    disabled: !adminStore.data,
    onChange: value => adminStore.getUsers({ page: value })
  }

  const data = useMemo(
    () =>
      adminStore.isFetchingData
        ? Array(15)
            .fill({})
            .map((_val, index) => ({ ...loadingRow, key: `loading-${index}` }))
        : adminStore.rows,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adminStore.rows, adminStore.isFetchingData, tablePagination.currentPage]
  )

  const disabledSortColumnKeys = ['groupselection_description', 'mobileNumber']

  const columns = useMemo(
    () =>
      adminStore.isFetchingData
        ? adminStore.columns.map(col => ({
            Header: col.name,
            accessor: col.key,
            Cell: <Skeleton width={100} />,
            disableSortBy: !!disabledSortColumnKeys.find(key => key === col.key)
          }))
        : adminStore.columns.map(col => ({
            Header: col.name,
            accessor: col.key,
            disableSortBy: !!disabledSortColumnKeys.find(key => key === col.key)
          })),
    [adminStore.isFetchingData] //eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleSort = useCallback(
    (key, desc) => {
      const sortUsers = async (key, desc) => {
        // No default column sort key in on initial fetch,
        // so default to empty [] when no sorting applied to return initial data
        const columnSorting = key ? [key, desc ? 'desc' : 'asc'] : []
        adminStore.setColumnSorting(columnSorting)
        // Get base results of sorted table state to display as fallback
        // prevents no cached results bug when backspacing sorted search results
        if (!adminStore.results.length && adminStore.searchTerm.length) {
          await adminStore.getUsers({
            columnSorting,
            searchTerm: ''
          })
        }
        await adminStore.getUsers({
          columnSorting
        })
      }
      if (adminStore.rows.length > 0) {
        sortUsers(key, desc)
      }
    },
    [adminStore]
  )

  const renderDetail = data => {
    if (!data) return
    let initialStatementCode = ''

    switch (true) {
      case data.status == 'Active':
        initialStatementCode = 'user-is-active'
        break

      case (data.status == 'Invited' || data.status == 'Link Expired') && !!data.userUniqueId:
        initialStatementCode = 'user-is-invited-w-uniqueId'
        break

      case data.status == 'Invited' && !data.userUniqueId:
        initialStatementCode = 'user-is-invited-no-uniqueId'
        break

      case data.status == 'Link Expired' && !data.userUniqueId:
        initialStatementCode = 'user-is-expired-no-uniqueId'
        break

      default:
        initialStatementCode = 'user-unknown-issue'
    }

    return (
      <SingleUserViewWrapper>
        <SingleUserRawDataWrapper>
          <h2>(Raw Data)</h2>
          <ul>
            <li>
              <div>Name:</div>
              <div>{data.name || '-'}</div>
            </li>
            <li>
              <div>Status:</div>
              <div>{data.status || '-'}</div>
            </li>
            <li>
              <div>UniqueId:</div>
              <div>{data.userUniqueId || '-'}</div>
            </li>
            <li>
              <div>Email:</div>
              <div>{data.email || '-'}</div>
            </li>
            <li>
              <div>Invite Email:</div>
              <div>{data.inviteEmail || '-'}</div>
            </li>
            <li>
              <div>Last Login:</div>
              <div>{data.lastLogin || '-'}</div>
            </li>
            <li>
              <div>Activation DateTime:</div>
              <div>{data.activationDateTime || '-'}</div>
            </li>
            <li>
              <div>Invitation-Sent DateTime:</div>
              <div>{data.invitationSentDateTime || '-'}</div>
            </li>
            <li>
              <div>Invitation-Expiry DateTime:</div>
              <div>{data.accessExpiryDateTime || '-'}</div>
            </li>
          </ul>
        </SingleUserRawDataWrapper>
        <SingleUserMainDataWrapper>
          <AdminDecisionPanel initialStatementCode={initialStatementCode} userData={data} />
        </SingleUserMainDataWrapper>
      </SingleUserViewWrapper>
    )
  }

  if (!userStore.isAdmin) return <Redirect to={ROUTE_DASHBOARD} />

  return (
    <>
      <AdminMenuContainer>
        {/* <Button
          appearance='secondary'
          iconName='Security'
          onClick={() => {
            window.location = getSalesforcePasswordUrl('en', window.location.host, getBrandFromDomain())
          }}
        >
          Change Password
        </Button> */}

        <Button
          appearance='tertiary'
          iconName='User'
          onClick={async () => {
            await logout()
          }}
          dataAttributes={{ 'data-testid': 'button-logout' }}
        >
          Logout
        </Button>
      </AdminMenuContainer>

      <PageHeader title='Admin Panel' subtitle='Customer Service Representative Access - Authority/User search'>
        <LinkWrapper>
          <Link onClick={history.goBack} to='/admin/scenarios'>
            Review supported scenarios
            <Icon name='RightSmall' size='md' />
          </Link>
        </LinkWrapper>
      </PageHeader>

      <InnerPageWrapper>
        <ControlsWrapper style={{ flexDirection: 'column', alignSelf: 'flex-start' }}>
          <SearchWithTooltipWrapper>
            <SearchBox
              placeholder={tableSearchProps.placeholder}
              value={adminStore.searchTerm}
              onChange={tableSearchProps.onChange}
              onSubmit={tableSearchProps.onSubmit}
              onClear={tableSearchProps.onClear}
              minLength={tableSearchProps.minLength}
            />
          </SearchWithTooltipWrapper>
          <div>
            {adminStore.searchTerm.length >= tableSearchProps.minLength &&
              (isAuthoritySearch ? <>(Authority search detected)</> : <>(User search detected)</>)}
          </div>
        </ControlsWrapper>

        {!(data.length === 0 && adminStore.searchTerm && displayModal) && (
          <ReactTable
            name='users-administration'
            columns={columns}
            hideCheckboxColumn
            data={data}
            pagination={tablePagination}
            actions={tableActionProps}
            onSort={handleSort}
            isLoading={adminStore.isFetchingData}
            renderDetail={({ original }) => renderDetail(original)}
          />
        )}
      </InnerPageWrapper>

      {data.length === 0 && adminStore.searchTerm && displayModal && (
        <NoDataWrapper>
          <SingleUserMainDataWrapper>
            <AdminDecisionPanel
              initialStatementCode={isAuthoritySearch ? 'authorityid-not-found' : 'email-not-found'}
              userData={data}
            />
          </SingleUserMainDataWrapper>
        </NoDataWrapper>
      )}
    </>
  )
}

export default observer(AdminPage)
