import { RESTRICTION_TYPES } from 'constants/restrictions'
import { formatCategoriesAndProducts } from 'helpers/restrictions'

const checkEmptyValue = value => (value && !!value.toString() ? value : null)

const buildRequest = ({ authorityId, profileName, restrictionTypes, data }) => {
  const categoriesData =
    data.productAndServices &&
    formatCategoriesAndProducts(data.productAndServices.categories, data.productAndServices.productCategories)

  const request = {
    profileLevel: 'A',
    authorityId,
    parentId: null,
    restrictionDetail: {
      saveProfile: true,
      profileId: '',
      profileName,
      restrictionTypes: restrictionTypes,
      existingProfile: false,
      ...(restrictionTypes.includes(RESTRICTION_TYPES.SPENDING_LIMIT) && {
        spendingLimit: {
          ...data.spendingLimit
        }
      }),
      ...(restrictionTypes.includes(RESTRICTION_TYPES.PRODUCTS_AND_SERVICES) && {
        productAndServices: {
          acceptProductServiceViolation: data.productAndServices.violationId === 3 ? '0' : '1',
          alertProductServiceViolation: data.productAndServices.violationId === 1 ? '0' : '1',
          isAllowNotListed: data.productAndServices.isAllowNotListed,
          categories: categoriesData
            .filter(item => data.productAndServices.selectedCategoryIds.includes(Number(item.categoryId)))
            .map(item => ({
              categoryId: Number(item.categoryId),
              applyForAllProductsInCategory: item.applyForAllProductsInCategory,
              categoryDetails: {
                categoryLimit: item.applyForAllProductsInCategory
                  ? {
                      daily: {
                        dailyMaxVolume: checkEmptyValue(item.dailyMaxVolume),
                        dailyMaxValue: checkEmptyValue(item.dailyMaxValue)
                      },
                      monthly: {
                        monthlyMaxValue: null,
                        monthlyMaxVolume: null
                      },
                      transaction: {
                        txnMaxVolume: checkEmptyValue(item.txnMaxVolume),
                        txnMaxValue: checkEmptyValue(item.txnMaxValue)
                      }
                    }
                  : null,
                isAllow: item.applyForAllProductsInCategory ? Number(item.isAllow) : null,
                products: item.applyForAllProductsInCategory
                  ? null
                  : item.products.map(product => ({
                      productServiceId: Number(product.productId),
                      productServiceName: product.name,
                      productServiceType: product.type,
                      isAllow: Number(product.isAllow),
                      ...(Number(product.isAllow) === 1 &&
                        product.setRestriction && {
                          productDetails: {
                            ...(product.dailyMaxVolume | product.dailyMaxValue && {
                              daily: {
                                dailyMaxVolume: checkEmptyValue(product.dailyMaxVolume),
                                dailyMaxValue: checkEmptyValue(product.dailyMaxValue)
                              }
                            }),
                            ...(product.txnMaxVolume | product.txnMaxValue && {
                              transaction: {
                                txnMaxVolume: checkEmptyValue(product.txnMaxVolume),
                                txnMaxValue: checkEmptyValue(product.txnMaxValue)
                              }
                            })
                          }
                        })
                    }))
              }
            }))
        }
      }),
      ...(restrictionTypes.includes(RESTRICTION_TYPES.ODOMETER) && {
        odometerAndDistance: {
          acceptOdometerViolation:
            data.odometerAndDistance.restrictionDetail.odometerAndDistance.acceptOdometerViolation,
          alertOdometerViolation: data.odometerAndDistance.restrictionDetail.odometerAndDistance.alertOdometerViolation,
          maximumDistance: data.odometerAndDistance.restrictionDetail.odometerAndDistance.maximumDistance
        }
      }),
      ...(restrictionTypes.includes(RESTRICTION_TYPES.DAY_AND_TIME) && {
        dayTime: {
          acceptDayTimeViolation: data.dayTime.restrictionDetail.dayTime.acceptDayTimeViolation,
          alertDayTimeViolation: data.dayTime.restrictionDetail.dayTime.alertDayTimeViolation,
          type: data.dayTime.restrictionDetail.dayTime.type,
          daysWithTime: data.dayTime.restrictionDetail.dayTime.daysWithTime
        }
      }),
      ...(restrictionTypes.includes(RESTRICTION_TYPES.SITE_LOCATIONS) && {
        siteLocations: {
          acceptSitesViolation: data.siteLocations.violationId === 3 ? '0' : '1',
          alertSitesViolation: data.siteLocations.violationId === 1 ? '0' : '1',
          isAllowNotListed: data.siteLocations.allowNotListed ? '1' : '0',
          allowedSiteGroupIds: data.siteLocations.siteTypes
            .filter(type => type.value)
            .map(({ siteGroupId }) => siteGroupId),
          deniedSiteGroupIds: data.siteLocations.siteTypes
            .filter(type => !type.value)
            .map(({ siteGroupId }) => siteGroupId),
          sites: data.siteLocations.restrictionList.map(site => ({
            isAllow: site.allow ? 1 : 0,
            countryCode: site.selectedCountry.countryCode,
            countryId: site.selectedCountry.countryId,
            areaId: site.selectedRegion.areaId,
            site: {
              globalSiteId: site.selectedSite.globalSiteId,
              siteTypeId: site.selectedSite.siteTypeId || null,
              siteGroupId: site.selectedSite.siteGroupTypeId || null
            }
          }))
        }
      })
    }
  }

  return request
}

export default buildRequest
