import styled from 'styled-components'
import { CssVars } from '@bp-digital/theme'

export const AdminDecisionPanelWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;

  .adp-label {
    font-size: 0.9em;
    color: #666;
  }

  .adp-button {
    background-color: transparent;
    border: 0;
    text-decoration: underline;
    text-underline-position: from-font;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    > div {
      text-decoration: none;
    }
  }

  .adp-links {
    display: flex;
    gap: 10px;
    margin-bottom: 2em;

    a {
      color: blue;
      text-decoration: underline;
      text-underline-position: from-font;
    }
  }

  .adp-images {
    display: flex;
    gap: 10px;
    margin-bottom: 1em;

    li {
      width: 150px;

      button {
        padding: 2px;
        display: flex;
        flex-direction: column;
        border: 1px #666 solid;
        border-radius: 2px;
        justify-content: space-between;
      }

      img {
        margin: 0;
      }

      .adp-label {
        text-align: center;
      }
    }
  }

  h2 {
    font-size: 1.4rem;
    font-weight: normal;
    font-style: italic;
    margin-top: 0;
    margin-bottom: 1em;
  }
  img {
    width: 100%;
  }

  .adp-description {
    margin-bottom: 1.2em;
    font-size: 1.2em;
  }

  .adp-decisions {
    display: flex;

    div,
    icon,
    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .adp-no-entry {
    font-size: 0.9em;
    font-style: italic;
  }

  div.adp-decision-tile {
    border: 1px #ccc solid;
    padding: 10px;
  }

  div.adp-query {
    font-size: 1.4em;
    font-weight: normal;
    font-style: italic;
    font-weight: 700;
    margin-bottom: 1em;
  }

  ul {
    list-style-type: none;

    li {
      display: flex;
      flex-direction: row;
    }

    &.adp-suggestions {
      margin-left: 2em;

      li {
        margin-bottom: 1em;
      }
    }
  }
`

export const ImageWrapper = styled.div`
  width: 100%;

  img {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const MsgWrapper = styled.div`
  border: 2px ${CssVars.palette.state.neutralDark} solid;
  background-color: ${CssVars.palette.state.neutralLight};
  border-radius: 10px;
  padding: 10px;
  width: fit-content;
  margin-bottom: 5px;

  &.error {
    background-color: ${CssVars.palette.state.dangerLight};
    border-color: ${CssVars.palette.state.dangerDark};
  }

  &.success {
    background-color: ${CssVars.palette.state.successLight};
    border-color: ${CssVars.palette.state.successDark};
  }
`
