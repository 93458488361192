import axios from 'axios'
import { useMutation } from 'react-query'

const useApiDeleteStaticFile = (pricelistfromAzure: boolean) => useMutation(async params =>{
let url = '/api/pricelist'
 if (pricelistfromAzure) {
    url = '/api/transactions/pricingList/DeleteAsync'
   }

  return await axios.post(url, params )
})

export default useApiDeleteStaticFile
