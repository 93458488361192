import { EXPIRY_ID, IS_ADMIN, IDP_TOKEN, IDP_REFRESH } from 'constants/localStorage'
import axios from "axios"
import Cookies from "js-cookie"
import {  getCodeChallengeandCodeVerifier } from 'helpers/getCodeVerifier_Challenge'
import { getEnvironment } from '../getEnvironment'
import { PROD_ENV } from 'constants/environments'
import {
 azure_nonProdClientId,
 azure_prodClientId
} from 'constants/idpenv'


const env = getEnvironment()
const ad_oidcurl = 'https://login.microsoftonline.com/ea80952e-a476-42d4-aaf4-5457852b0f7e/oauth2/v2.0/authorize'
const client_id = getClientvalue()
export async function getAzureLoginPath() {
    const redirectUrl = window.location.origin
    //const locale = getLanguageFromUrl()
    const oidcURL = ad_oidcurl
    const {code_verifier,code_challenge} = await getCodeChallengeandCodeVerifier()
    const localStorage = window.localStorage
    localStorage.setItem('code_verifier', code_verifier)
    const codeChallenge = code_challenge
    const queryParams = [`client_id=${client_id}`]
    queryParams.push(`code_challenge=${codeChallenge}`)
    queryParams.push(`code_challenge_method=S256`)
    queryParams.push(`redirect_uri=${redirectUrl}`)
    queryParams.push(`response_type=code`)
    queryParams.push(`scope=openid email profile offline_access`)
    const res = `${oidcURL}?${queryParams.join('&')}`
    return res.toString()
}
export async function getAzureLogoutPath() {
    const redirectUrl = window.location.origin+'/en/login/internal'
    const brand = Cookies.get('brandfromdomain')
    await axios.post(
      `/api/users/token/gettokens?logout_action=logout`,
      { idtoken: localStorage.getItem(IDP_REFRESH), brand:brand },
      { skipAuthRefresh: true, withCredentials: true }
    )
    Cookies.remove('bpf-refresh-expiry')
    Cookies.remove('bpf-brand')
    Cookies.remove('bpf-imp')
    Cookies.remove('bpf-idp')
    localStorage.removeItem('code_verifier')
    removeItemsFromLocalStorage([IS_ADMIN, EXPIRY_ID, IDP_TOKEN, IDP_REFRESH])
    return redirectUrl
  }

export const removeItemsFromLocalStorage = (keys: string[]) => {
    keys.forEach(key => localStorage.removeItem(key))
}
export function getClientvalue() {
  return env == PROD_ENV ? azure_prodClientId : azure_nonProdClientId
}